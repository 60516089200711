<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Relatório de uso por departamento
    </hero-bar>
    <section class="section is-main-section">
      <DepartmentsReportFilters v-on:applyFilters="fetchAll" />

      <card-component title="Tempo médio de atendimento por departamento (por minuto)" icon="finance">
        <div v-if="defaultChart.departmentAverageCloseChart" class="chart-area">
          <pie-chart ref="bigChart" style="height: 100%;" chart-id="big-line-chart"
            :chart-data="defaultChart.departmentAverageCloseChart" :extra-options="defaultChart.extraOptions"
            @on-receive="() => alert('id')">
          </pie-chart>
        </div>
      </card-component>

      <card-component title="Tempo médio de espera por departamento (por minuto)" icon="finance">
        <div v-if="defaultChart.departmentAverageWaitingChart" class="chart-area">
          <pie-chart ref="bigChart" style="height: 100%;" chart-id="big-line-chart"
            :chart-data="defaultChart.departmentAverageWaitingChart" :extra-options="defaultChart.extraOptions"
            @on-receive="() => alert('id')">
          </pie-chart>
        </div>
      </card-component>

      <card-component title="Divisão de atendimento por departamento (Clique nas opções para mais detalhes de tickets)"
        icon="finance">
        <div v-if="defaultChart.attendantChart" class="chart-area">
          <pie-chart ref="bigChart" style="height: 100%;" chart-id="big-line-chart"
            :chart-data="defaultChart.attendantChart" :extra-options="defaultChart.extraOptions"
            @on-receive="() => alert('id')">
          </pie-chart>
        </div>
      </card-component>

      <card-component :title="getTicketsTableTitle()" class="has-table has-mobile-sort-spaced">
        <b-loading :is-full-page="false" v-model="fetchingDepartmentsReports" :can-cancel="true"></b-loading>
        <b-table :data="getDepartmentsReportsTickets" :bordered="true" :striped="true" paginated :mobile-cards="true"
          v-if="this.selectedOption" sort-icon="arrow-up" sort-icon-size="is-small" default-sort="id"
          aria-next-label="Próxima página" aria-previous-label="Página anterior" aria-page-label="Página"
          aria-current-label="Página atual" scrollable :show-detail-icon="true">
          <b-table-column sortable field="id" label="ID" v-slot="props" searchable>
            {{ props.row.id }}
          </b-table-column>

          <b-table-column field="client_name" label="Nome no whats" v-slot="props" searchable>
            {{ props.row.client_name }}
          </b-table-column>

          <b-table-column field="contact_name" label="Contato" v-slot="props" searchable>
            {{ props.row.contact_id && props.row.contact_name ? `${props.row.contact_id} -
                        ${props.row.contact_name}` : ''
            }}
          </b-table-column>

          <b-table-column field="client_phone" label="Telefone" v-slot="props" searchable>
            {{ props.row.client_phone }}
          </b-table-column>

          <b-table-column sortable field="is_group" label="Tipo" v-slot="props">
            <IsGroupTag :is_group="!!props.row.is_group" />
          </b-table-column>

          <b-table-column field="attendant_name" label="Atendente" v-slot="props" searchable>
            {{ props.row.attendant_username && props.row.attendant_name ? `${props.row.attendant_username} -
                        ${props.row.attendant_name}` : ''
            }}
          </b-table-column>

          <b-table-column sortable field="is_open" label="Status" v-slot="props">
            {{ props.row.is_open ? "Aberto" : "Fechado" }}
          </b-table-column>

          <b-table-column field="created_at" label="Data de abertura" v-slot="props">
            {{ formatDateTime(props.row.created_at) }}
          </b-table-column>

          <b-table-column field="actions" label="Mensagens" v-slot="props">
            <MessagesTicketButton :ticket="props.row" />
          </b-table-column>

          <b-table-column field="actions" label="Transferir" v-slot="props" v-if="getIsSupervisor">
            <TransferTicketButton :ticket="props.row" v-on:reloadTickets="info('reloadTickets')" />
          </b-table-column>

          <b-table-column field="actions" label="Fechar" v-slot="props" v-if="getIsSupervisor">
            <CloseTicketButton :ticket="props.row" v-on:reloadTickets="info('reloadTickets')" />
          </b-table-column>

          <template #footer>
            <div class="has-text-left">
              Quantidade de registros {{ getDepartmentsReportsTickets.length }}
            </div>
          </template>

          <template #detail="props">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <p v-if="props.row.ticket_reason">
                    Motivo do fechamento: <strong>{{ props.row.ticket_reason.id }} - {{ props.row.ticket_reason.title
                    }}</strong>
                    <br />
                  </p>
                  <p v-if="props.row.ticket_reason_explanation">
                    Explicação do atendente: <br />
                    <strong>{{ props.row.ticket_reason_explanation }}</strong>
                  </p>
                </div>
              </div>
            </article>
          </template>
        </b-table>
        <b-notification :closable="false" type="is-warning" v-else>
          Nenhuma opção de menu selecionada, por favor selecione uma opção de menu do gráfico acima para apresentar os
          tickets correspondentes.
        </b-notification>
      </card-component>

      <card-component :title="getAttendantsTableTitle()" class="has-table has-mobile-sort-spaced">
        <b-loading :is-full-page="false" v-model="fetchingDepartmentsReports" :can-cancel="true"></b-loading>
        <b-table :data="getDepartemntsReportsAttendants" :bordered="true" :striped="true" paginated :mobile-cards="true"
          v-if="this.selectedOption" sort-icon="arrow-up" sort-icon-size="is-small" default-sort="id"
          aria-next-label="Próxima página" aria-previous-label="Página anterior" aria-page-label="Página"
          aria-current-label="Página atual" scrollable :show-detail-icon="true">
          <b-table-column sortable field="username" label="Username" v-slot="props" searchable>
            {{ props.row.username }}
          </b-table-column>

          <b-table-column field="name" label="Nome" v-slot="props" searchable>
            {{ props.row.name }}
          </b-table-column>

          <b-table-column field="supervisor" label="É supervisor" v-slot="props">
            <YesNoTag :isYes="!!props.row.departments[0].supervisor" />
          </b-table-column>

          <b-table-column field="supervisor_all_departments" label="Sup. todos dep." v-slot="props">
            <YesNoTag :isYes="!!props.row.supervisor_all_departments" />
          </b-table-column>

          <b-table-column field="custom_code" label="Código customizado" v-slot="props" searchable>
            {{ props.row.custom_code }}
          </b-table-column>

          <b-table-column field="created_at" label="Código customizado" v-slot="props">
            {{
                props.row.created_at
                  ? formatDateTime(props.row.created_at)
                  : ""
            }}
          </b-table-column>

          <b-table-column field="isActive" label="Código customizado" v-slot="props">
            <IsActiveTag :active="!!props.row.isActive" />
          </b-table-column>

          <template #footer>
            <div class="has-text-left">
              Quantidade de registros {{ getDepartemntsReportsAttendants.length }}
            </div>
          </template>

          <template #detail="props">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <p v-if="props.row.ticket_reason">
                    Motivo do fechamento: <strong>{{ props.row.ticket_reason.id }} - {{ props.row.ticket_reason.title
                    }}</strong>
                    <br />
                  </p>
                  <p v-if="props.row.ticket_reason_explanation">
                    Explicação do atendente: <br />
                    <strong>{{ props.row.ticket_reason_explanation }}</strong>
                  </p>
                </div>
              </div>
            </article>
          </template>
        </b-table>
      </card-component>
    </section>

    <ModalTicketDetails v-if="isModalTicketDetailsOpen" :ticket="selectedTicket" :showMessagesOption="true"
      v-on:closeModal="closeModal" :isComponentModalActive="isModalTicketDetailsOpen" />
  </div>
</template>

<script>

// @ is an alias to /src
import * as chartConfig from '@/components/Charts/chart.config'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'
import PieChart from '@/components/Charts/PieChart'
import IsGroupTag from '@/components/commons/IsGroupTag.vue'
// import ClientsTableSample from '@/components/ClientsTableSample'
import { mapActions, mapGetters } from 'vuex'
import ModalTicketDetails from '@/components/modals/ModalTicketDetails'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import mixin from '../utils/mixins'
import CloseTicketButton from '@/components/tickets/CloseTicketButton'
import TransferTicketButton from '@/components/tickets/TransferTicketButton'
import MessagesTicketButton from '@/components/tickets/MessagesTicketButton'
import DepartmentsReportFilters from '@/components/departmentsReports/DepartmentsReportFilters'
import YesNoTag from '@/components/commons/YesNoTag.vue'
import IsActiveTag from '@/components/commons/IsActiveTag.vue'

export default {
  name: 'DepartmentsReports',
  components: {
    PieChart,
    CardComponent,
    HeroBar,
    TitleBar,
    ModalTicketDetails,
    IsGroupTag,
    CloseTicketButton,
    TransferTicketButton,
    MessagesTicketButton,
    DepartmentsReportFilters,
    YesNoTag,
    IsActiveTag
  },
  mixins: [mixin],
  data () {
    return {
      isModalTicketDetailsOpen: false,
      selectedTicket: null,
      selectedOption: null,
      currentFilters: {},
      departmentAverageClose: [],
      departmentAverageWaiting: [],
      defaultChart: {
        ticketsChartData: null,
        statusChartData: null,
        attendantChart: null,
        departmentChart: null,
        departmentAverageCloseChart: null,
        departmentAverageWaitingChart: null,
        extraOptions: {
          ...chartConfig.chartOptionsMain,
          scales: {
            yAxes: [
              {
                id: 'y-attendants',
                display: true,
                type: 'linear',
                ticks: {
                  suggestedMin: 0, // minimum will be 0, unless there is a lower value.
                  // OR //
                  beginAtZero: true // minimum value will be 0.
                }
              }, {
                id: 'y-tickets',
                type: 'linear',
                position: 'right',
                gridLines: {
                  display: false
                },
                ticks: {
                  suggestedMax: 15,
                  suggestedMin: 0, // minimum will be 0, unless there is a lower value.
                  // OR //
                  beginAtZero: true // minimum value will be 0.
                }
              }
            ]
          },
          legend: {
            display: true
          },
          onClick: (point, event) => {
            const item = event[0]
            if (!item) return

            this.onChartClick({ index: item._index, label: item._view.label })
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'fetchingDepartmentsReports',
      'getDepartmentsReports',
      'getDepartmentsReportsTickets',
      'getIsSupervisor',
      'getDepartemntsReportsAttendants'
    ]),
    titleStack () {
      return ['Admin', 'Reports', 'Departamentos']
    }
  },
  methods: {
    ...mapActions([
      'fetchDepartmentsReports',
      'fetchDepartmentsReportsTickets',
      'clearDepartmentsReportsTicketsData',
      'fetchDepartmentsReportsAttendants'
    ]),
    getTicketsTableTitle () {
      if (!this.selectedOption) return 'Selecione uma opção de menu para exibir os tickets correspondentes'
      return `Tickets correspondentes ao departamento (${this.selectedOption.department_id}) - ${this.selectedOption.department_name}`
    },
    getAttendantsTableTitle () {
      if (!this.selectedOption) return 'Selecione uma opção de menu para exibir os atendentes correspondentes'
      return `Atendentes correspondentes ao departamento (${this.selectedOption.department_id}) - ${this.selectedOption.department_name}`
    },
    async fetchAll (data) {
      this.currentFilters = data
      await this.fetchDepartmentsReports(data)

      this.fillChartData()
    },
    async onChartClick (item) {
      this.selectedOption = this.getDepartmentsReports[item.index]
      this.fetchDepartmentsReportsTickets({ ...this.currentFilters, department_id: this.selectedOption.department_id })
      this.fetchDepartmentsReportsAttendants({ ...this.currentFilters, department_id: this.selectedOption.department_id })
    },
    fillChartData () {
      const departmentsAttendantsReports = []
      const departmentsTicketsReports = []
      this.departmentAverageClose = []
      this.departmentAverageWaiting = []
      this.selectedOption = null
      this.clearDepartmentsReportsTicketsData()
      this.getDepartmentsReports.map(tkt => {
        departmentsAttendantsReports[`(${tkt.department_id}) - ${tkt.department_name}`] = tkt.attendants_count
        departmentsTicketsReports[`(${tkt.department_id}) - ${tkt.department_name}`] = tkt.tickets_count
      })
      this.getDepartmentsReports.map(tkt => {
        this.departmentAverageClose[`(${tkt.department_id}) - ${tkt.department_name}`] = tkt.average_time_to_close_ticket
      })

      this.getDepartmentsReports.map(tkt => {
        this.departmentAverageWaiting[`(${tkt.department_id}) - ${tkt.department_name}`] = tkt.average_time_to_select_ticket
      })

      this.defaultChart.attendantChart = {
        datasets: [
          {
            yAxisID: 'y-attendants',
            backgroundColor: 'rgb(255, 99, 132)',
            spacing: 4,
            data: [...Object.values(departmentsTicketsReports)],
            label: 'Tickets'
          },
          {
            yAxisID: 'y-tickets',
            backgroundColor: 'rgb(54, 162, 235)',
            data: [...Object.values(departmentsAttendantsReports)],
            label: 'Atendentes',
            position: 'right'
          }
        ],
        labels: [...Object.keys(departmentsAttendantsReports)]
      }

      this.defaultChart.departmentAverageCloseChart = {
        datasets: [
          {
            backgroundColor: 'rgb(79, 176, 255)',
            hoverOffset: 4,
            data: [...Object.values(this.departmentAverageClose)],
            label: 'Tempo médio de atendimento por departamento (por minuto)'
          }
        ],
        labels: [...Object.keys(departmentsAttendantsReports)]
      }
      this.defaultChart.departmentAverageWaitingChart = {
        datasets: [
          {
            backgroundColor: 'rgb(255, 249, 79)',
            hoverOffset: 4,
            data: [...Object.values(this.departmentAverageWaiting)],
            label: 'Tempo médio de espera por departamento (por minuto)' 
          }
        ],
        labels: [...Object.keys(departmentsAttendantsReports)]
      }
    }
  }
}
</script>
