<template>
  <span class="tag group" v-if="is_group">
    Grupo
  </span><span class="tag is-success" v-else>
    Pessoa
  </span>
</template>

<script>
export default {
  name: 'IsGroupTag',
  props: {
    is_group: {
      type: Boolean,
      require: true
    }
  }
}
</script>

<style lang="scss" scoped>
.group {
  background-color: rgb(148, 69, 174) !important;
  color: #FFFFFF !important;
}
</style>
