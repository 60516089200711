<template>
  <div class="row columns is-multiline is-vcentered">
    <div class="column is-one-quarter">
      <b-field label="Contatos" label-position="on-border">
        <multiselect v-model="contactsFilter" :multiple="true" :options="getContacts" label="name" track-by="id"
          select-label="" deselect-label="">
          <template slot="placeholder">Contatos</template>
        </multiselect>
      </b-field>
    </div>
    <div class="column is-one-quarter">
      <b-field label="Nome/Tel. do cliente" label-position="on-border">
        <b-input expanded type="search" v-model="namePhoneFilter" placeholder="Nome ou telefone do cliente"
          @keydown.native.enter="applyFilters()">
        </b-input>
      </b-field>
    </div>
    <div class="column is-one-quarter">
      <b-field label="Status" label-position="on-border">
        <b-select expanded v-model="statusFilter" @click.native="statusChange()">
          <option v-for="status in ticketStatus" :value="status.value" :key="status.value">
            {{ status.text }}
          </option>
        </b-select>
      </b-field>
    </div>
    <div class="column is-one-quarter">
      <b-field label="Motivo do fechamento" label-position="on-border">
        <b-select expanded v-model="reasonFilter" :disabled="disabledReasonFilter">
          <option value="" key=""></option>
          <option v-for="reason in allReasons" :value="reason.id" :key="reason.id">
            {{ reason.title }}
          </option>
        </b-select>
      </b-field>
    </div>
    <div class="column is-one-quarter">
      <b-field label="Data de" label-position="on-border">
        <b-input expanded type="date" v-model="dateFromFilter" placeholder="Nome ou telefone do cliente"
          @keydown.native.enter="applyFilters()">
        </b-input>
      </b-field>
    </div>
    <div class="column is-one-quarter">
      <b-field label="Data até" label-position="on-border">
        <b-input expanded type="date" v-model="dateToFilter" placeholder="Nome ou telefone do cliente"
          @keydown.native.enter="applyFilters()">
        </b-input>
      </b-field>
    </div>
    <div class="column is-one-quarter">
      <b-field label="Número de origem" label-position="on-border">
        <b-select placeholder="" v-model="wwPhoneSelected" expanded v-if="allSettings.length">
          <option v-for="setting in allSettings" :value="setting.phone" :key="setting.phone">
            {{ setting.name ? setting.name + ' - ' + setting.phone : setting.phone }}
          </option>
        </b-select>
      </b-field>
    </div>
    <div class="column is-one-quarter">
      <p class="control">
        <button class="button is-primary is-fullwidth" @click="applyFilters()">
          Filtrar
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import multiselect from 'vue-multiselect'
import { addDays } from 'date-fns'

export default {
  name: 'DepartmentsReportFilters',
  components: {
    multiselect
  },
  props: {
  },
  data () {
    return {
      namePhoneFilter: '',
      contactsFilter: [],
      dateFromFilter: '',
      dateToFilter: '',
      reasonFilter: '',
      disabledReasonFilter: false,
      statusFilter: 'TOD',
      withoutDepartmentFilter: false,
      wwPhoneSelected: {},
      ticketStatus: [
        {
          value: 'TOD',
          text: 'Todos'
        },
        {
          value: 'ABE',
          text: 'Abertos'
        },
        {
          value: 'ABS',
          text: 'Abertos sem atendente'
        },
        {
          value: 'ABA',
          text: 'Abertos com atendente'
        },
        {
          value: 'FEC',
          text: 'Fechados'
        }
      ],
      selectedTicket: null
    }
  },
  computed: {
    ...mapGetters([
      'allContacts',
      'allReasons',
      'getIsSupervisor',
      'allSettings'
    ]),
    getContacts () {
      return this.allContacts.map((contact) => {
        const c = contact
        c.code = c.id
        c.name = `${c.name}`
        return c
      })
    }

  },
  methods: {
    ...mapActions([
      'fetchContacts',
      'fetchReasons',
      'transferTicket',
      'fetchSettings'
    ]),
    statusChange () {
      if (this.statusFilter === 'TOD' || this.statusFilter === 'FEC') {
        this.disabledReasonFilter = false
      } else {
        this.disabledReasonFilter = true
        this.reasonFilter = ''
      }
    },
    applyFilters () {
      this.$emit('applyFilters', {
        ww_phone: this.wwPhoneSelected,
        client_name_phone: this.namePhoneFilter,
        contacts_filter: this.contactsFilter,
        ticket_reason_id: this.reasonFilter,
        date_from_filter: this.dateFromFilter,
        date_to_filter: this.dateToFilter,
        ticket_status: this.statusFilter,
        tickets_without_department: this.withoutDepartmentFilter
      })
    }
  },
  async mounted () {
    this.dateFromFilter = addDays(new Date(), -5).toISOString().slice(0, 10)
    this.dateToFilter = new Date().toISOString().slice(0, 10)
    this.fetchContacts()
    this.fetchReasons()

    await this.fetchSettings()
    if (this.allSettings.length) {
      this.wwPhoneSelected = this.allSettings[0].phone
    }
    this.applyFilters()
  }
}
</script>

<style lang="scss" scoped>
.multiselect--active {
  z-index: 9999;
}

.multiselect__content-wrapper {
  z-index: 9999 !important;
}
</style>
